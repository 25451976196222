import { graphql } from 'gatsby'
import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { Accordion, Card, Button } from 'react-bootstrap'
import get from 'lodash/get'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

class Faqs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showItems: [true],
      pageData: get(this.props.data, 'contentfulFaqs'),
    }
  }

  showCalendy = e => {
    Calendly.initPopupWidget({
      url:
        'https://calendly.com/limitlesswithjess/initial-90-min-consultation?text_color=4a4a4a&primary_color=ff0021',
    })
  }

  onClickList = (event, index) => {
    event.preventDefault()
    this.state.showItems[index] = !this.state.showItems[index]
  }

  render() {
    const { location } = this.props
    const { pageData, showItems } = this.state
    const siteMetadata = {
      title: 'Faqs | Limitless With Jess',
      description: 'Gatsby starter for bootstrap a blog',
      siteUrl: 'https://limitlesswithjess.com/faqs',
    }

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} />
        <div className="content-wrapper">
          <div className="main-wrapper clearfix">
            <div className="widget-list">
              <div
                id="faq-page__title-widget"
                className="widget-holder mx-auto p-0"
              >
                <div
                  className="widget-bg pb-150 pt-200"
                  style={{
                    background: `url(${pageData.bannerImage.file.url})`,
                    backgroundSize: 'cover',
                  }}
                >
                  <div className="widget-body container">
                    <div className="row">
                      <div className="col-12 text-center">
                        <h4 id="faq-page__title" className="fw-600">
                          {pageData.heading1 ? pageData.heading1 : ''}
                        </h4>
                        <p className="faq-bnr-txt mr-b-60 ">
                          {pageData.heading2 ? pageData.heading2 : ''}
                        </p>
                        <Scrollspy
                          items={['faq-page__accordion-widget']}
                          className="slider-quote-btn scrollContentData pl-0"
                        >
                          <Scroll
                            type="id"
                            element="faq-page__accordion-widget"
                          >
                            <button
                              className="btn btn-sm btn-circle btn-secondary scroll-to-element"
                              id="faq-page__title-scroll-btn"
                            >
                              <i className="feather-chevron-down"></i>
                            </button>
                          </Scroll>
                        </Scrollspy>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="faq-page__accordion-widget" className="widget-holder">
                <div className="widget-bg">
                  <div className="widget-body container">
                    <div className="row">
                      <div className="col-md-8 mx-auto">
                        <div className="accordion accordion-minimal" id="acc-4">
                          <Accordion defaultActiveKey={0}>
                            {pageData.faqs
                              ? pageData.faqs.map((item, index) => {
                                  return (
                                    <Card className="card" key={index}>
                                      <Card.Header
                                        className="card-header"
                                        id="acc-heading-41"
                                      >
                                        <Accordion.Toggle
                                          as={Button}
                                          variant="link"
                                          eventKey={index}
                                          onClick={event =>
                                            this.onClickList(event, index)
                                          }
                                          className={
                                            showItems[index]
                                              ? 'btn btn-link'
                                              : 'btn btn-link collapsed'
                                          }
                                        >
                                          {item.question ? item.question : ''}
                                        </Accordion.Toggle>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey={index}>
                                        <Card.Body className="card-body">
                                          {item.answer ? item.answer : ''}
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  )
                                })
                              : ''}
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="faq-page__contact-info-widget" className="widget-holder">
                <div className="widget-bg">
                  <div className="widget-body container">
                    <div className="row">
                      <div className="col-md-8 col-sm-12 mx-auto text-center">
                        <h4 className="fw-500">
                          {pageData.bottomSectionHeading1
                            ? pageData.bottomSectionHeading1
                            : ''}
                        </h4>
                        <p className="mr-b-20">
                          {pageData.bottomSectionHeading2
                            ? pageData.bottomSectionHeading2
                            : ''}
                        </p>
                      </div>
                      <div className="col-md-8 col-sm-12 mx-auto">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="contact-info-icon-left"
                              id="faq-page__contact-info-1"
                            >
                              <i className="feather-phone-call"></i>
                              <div className="contact-info-content">
                                <h5 className="contact-info-title">Phone:</h5>
                                <span>
                                  {' '}
                                  {pageData.bottomSectionPhone
                                    ? pageData.bottomSectionPhone
                                    : 'N/A'}
                                </span>{' '}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className="contact-info-icon-left"
                              id="faq-page__contact-info-2"
                            >
                              <i className="feather-mail"></i>
                              <div className="contact-info-content">
                                <h5 className="contact-info-title">Email:</h5>
                                <span>
                                  {' '}
                                  {pageData.bottomSectionEmail
                                    ? pageData.bottomSectionEmail
                                    : 'N/A'}
                                </span>{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="widget-holder btn-schdule">
                <div className="widget-bg">
                  <div className="widget-body container-fluid">
                    <div className="row ">
                      <div className="col">
                        <div href="#" className="">
                          <h2 className="text-white color-white">
                            {pageData.appointmentSectionHeading1
                              ? pageData.appointmentSectionHeading1
                              : ''}
                          </h2>
                          <button
                            onClick={e => this.showCalendy(e)}
                            className="btn btn-white bg-white"
                          >
                            {pageData.appointmentSectionButtonText
                              ? pageData.appointmentSectionButtonText
                              : ''}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Faqs

export const query = graphql`
  {
    contentfulFaqs {
      heading1
      heading2
      bannerImage {
        file {
          url
        }
        description
      }
      faqs {
        question
        answer
      }
      bottomSectionHeading1
      bottomSectionHeading2
      bottomSectionEmail
      bottomSectionPhone
      appointmentSectionHeading1
      appointmentSectionButtonText
    }
  }
`
