import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = () => (
  <div className="footer">
    {/* <!-- /.content-wrapper --> */}
    <div className="widget-holder col-md-12 p-0" id="main-footer">
      <div className="widget-bg text-white">
        <div className="widget-body container-fluid">
          <div className="row">
            <div className="col-md-10 mx-auto text-center">
              <figure className="mr-b-20">
                <Link to="/">
                  <img src="/assets/img/logo-footer.png" alt="" />
                </Link>
              </figure>
              {/* <!-- Footer Navigation --> */}
              <nav className="nav-container mr-b-10">
                <ul className="generic-nav">
                  <li className="menu-item current-menu-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/services">Services</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/health-analysis">Health Analysis</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/faqs">F.A.Q</Link>
                  </li>
                  {/* <li className="menu-item">
                    <Link to="/gallery-list">Gallery</Link>
                  </li> */}
                  <li className="menu-item">
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/limitless-learning">Limitless Learning</Link>
                  </li>
                  {/* <li className="menu-item">
                  <a href="https://limitless-with-jess.myshopify.com/" target="_blank">Store</a>
                  </li> */}
                </ul>
                {/* <!-- /.generic-nav --> */}
              </nav>
              {/* <!-- /.nav-container --> */}
              {/* <!-- Social Links --> */}
              <ul className="social-list social-list-colored-circle">
                <li>
                  <a
                    className="bg-facebook"
                    target="_blank"
                    href="https://www.facebook.com/Limitless-with-Jess-101863808194385/?ref=search&__tn__=%2Cd%2CP-R&eid=ARAGHyQ_ZIgcmpzJaofOdAWkabmkrUL3ha43q2FkAiaHOt7AJbJrXN897IvF-5SVw4ESb1wIxF2m8msD"
                  >
                    <i className="feather-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="bg-instagram"
                    target="_blank"
                    href="https://www.instagram.com/limitlesswithjess/?hl=en"
                  >
                    <i className="feather-instagram"></i>
                  </a>
                </li>
                <li>
                  <Link to="twitter.com" className="bg-twitter">
                    <i className="feather-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="youtube.com" className="bg-youtube">
                    <i className="feather-youtube"></i>
                  </Link>
                </li>
              </ul>
              {/* <!-- /.social-list --> */}
            </div>
            {/* <!-- /.col-md-12 --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.widget-body --> */}
      </div>
      {/* <!-- /.widget-bg --> */}
    </div>
    {/* <!-- /.widget-holder --> */}
    <div className="widget-holder col-md-12 p-0" id="main-subfooter">
      <div className="widget-bg text-white">
        <div className="widget-body container-fluid">
          <div className="row">
            <div className="col-md-10 mx-auto text-center">
              <div className="text-center">
                <p className="fs-12 text-muted cprit-txt">
                  LIMITLESS WITH JESS © 2020 All rights reserved.{' '}
                  <a href="#">Terms of Use and Privacy Policy.</a>
                </p>
              </div>
            </div>
            {/* <!-- /.col-md-12 --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.widget-body --> */}
      </div>
      {/* <!-- /.widget-bg --> */}
    </div>
  </div>
)

export default Footer
